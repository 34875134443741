export const CATEGORIES = [
  'Entertainment',
  'Drama',
  'Documentary',
  'Channel Operation',
  'Branded',
  'Motion Graphic',
  'Animation',
  'Live Commerce',
];

export const CountViewMenu=[
  'ALL',
  'MAIN', 
  'ABOUT', 
  'ARTWORK', 
  'FAQ', 
  'CONTACT', 
  'NEWS', 
  'RECRUITMENT'
]
export const CountViewArtworkCategory=[
  'ALL', 
  'ENTERTAINMENT', 
  'DRAMA', 
  'DOCUMENTARY', 
  'CHANNEL_OPERATING', 
  'BRANDED', 
  'MOTION_GRAPHIC', 
  'ANIMATION', 
  'LIVE_COMMERCE'
]

export const CountRequestCATEGORIES = [
  'all',
  'Entertainment',
  'Drama',
  'Documentary',
  'Channel Operation',
  'Branded',
  'Motion Graphic',
  'Animation',
  'Live Commerce',
];

export const CountRequestSTATE=[
  'ALL',
  'WAITING',
  'APPROVED',
  'REJECTED',
  'DISCUSSING',
];